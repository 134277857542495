(function (factory) {
  'use strict';

  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery', './version'], factory);
  } else {
    // Browser globals
    factory(jQuery);
  }
})(function ($) {
  'use strict';

  // $.ui.plugin is deprecated. Use $.widget() extensions instead.
  return ($.ui.plugin = {
    add: function (module, option, set) {
      var i,
        proto = $.ui[module].prototype;
      for (i in set) {
        proto.plugins[i] = proto.plugins[i] || [];
        proto.plugins[i].push([option, set[i]]);
      }
    },
    call: function (instance, name, args, allowDisconnected) {
      var i,
        set = instance.plugins[name];

      if (!set) {
        return;
      }

      if (
        !allowDisconnected &&
        (!instance.element[0].parentNode ||
          instance.element[0].parentNode.nodeType === 11)
      ) {
        return;
      }

      for (i = 0; i < set.length; i++) {
        if (instance.options[set[i][0]]) {
          set[i][1].apply(instance.element, args);
        }
      }
    },
  });
});
