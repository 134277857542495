// This file is deprecated in 1.12.0 to be removed in 1.14
(function () {
  'use strict';

  require('./data');
  require('./focusable');
  require('./ie');
  require('./keycode');
  require('./jquery-patch');
  require('./plugin');
  require('./safe-active-element');
  require('./safe-blur');
  require('./scroll-parent');
  require('./unique-id');
  require('./version');
})();
