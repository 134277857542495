(function (factory) {
  'use strict';

  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery', './version'], factory);
  } else {
    // Browser globals
    factory(jQuery);
  }
})(function ($) {
  'use strict';

  return ($.ui.safeBlur = function (element) {
    // Support: IE9 - 10 only
    // If the <body> is blurred, IE will switch windows, see #9420
    if (element && element.nodeName.toLowerCase() !== 'body') {
      $(element).trigger('blur');
    }
  });
});
